import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import styled, { keyframes, css } from 'styled-components';
import { Helmet } from 'react-helmet';

import GlobeIcon from 'shared/designTokens/icons/content/GlobeIcon';
import GameControllerIcon from 'shared/designTokens/icons/content/GameControllerIcon';
import WowIcon from 'shared/designTokens/icons/content/WowIcon';
import MonetizeIcon from 'shared/designTokens/icons/content/MonetizeIcon';
import DevDocIcon from 'shared/designTokens/icons/content/DevDocIcon';
import BallRugbyIcon from 'shared/designTokens/icons/content/BallRugbyIcon';

import { useSelectIsAuthenticated } from 'app/src/selectors/session';
import { useSelectPermissions, useSelectUser } from 'app/src/selectors/user';
import { lightColors, smallMedia, mediumMedia, ultraTinyMedia, largeMedia, giantMedia, tinyMedia, clipPathBottom, clipPathTop } from 'shared/vars';
import { spawnEmoji } from 'shared/utils/emojiHover';
import Button from 'app/src/components/ui/Button';
import MarketingFooter from 'shared/components/MarketingFooter';
import MarketingHeader from 'site/src/components/MarketingHeader';
import checkPermissions from 'app/src/utils/checkPermissions';
import getCDNImage from 'site/src/utils/getCDNImage';
import { Container, H1, H2, H3, P, Page, SectionContainer, Title } from 'site/src/components/styled';

const ButtonWrapper = styled(Button)``;

const HeroSection = styled.section`
	position: relative;
	background-position: bottom;
	background-image: url(/images/hero-bg.svg);
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 750px;
	padding-top: 100px;
	padding-bottom: 50px;
	z-index: 1;

	${smallMedia} {
		padding-top: 120px;
	}

	${mediumMedia} {
		padding-top: 0;
		height: 80vh;
	}
`;

const HeroColumn = styled.div`
	&:first-child {
		display: flex;
		align-items: center;
	}

	&:last-child {
		right: 0;
	}

	${mediumMedia} {
		height: 100%;
		position: absolute;
		top: 0;
		width: 45%;
	}
`;

const HeroContent = styled.div`
	padding: 48px 24px;

	${mediumMedia} {
		padding: 0;
	}
`;

const IntroText = styled.div`
	padding: 25px 0;

	${mediumMedia} {
		padding: 25px 0;
	}
`;

const ImageCardsContainer = styled.div`
	position: relative;
	height: 700px;
	margin-top: -50px;
	width: 330px;
	margin-left: auto;
	margin-right: auto;

	${smallMedia} {
		width: 460px;
	}

	${mediumMedia} {
		width: auto;
		margin-top: 0;
	}

	${largeMedia} {
		margin-left: 100px;
	}
`;

const imageCardAnimations = {
	topLeft: keyframes`
		0% {
			transform: translate(0, 0)
		}

		50% {
			transform: translate(-25px, -25px)
		}

		100% {
			transform: translate(0, 0)
		}
	`,
	topRight: keyframes`
		0% {
			transform: translate(0, 0)
		}

		50% {
			transform: translate(25px, -25px)
		}

		100% {
			transform: translate(0, 0)
		}
	`,
	bottomLeft: keyframes`
		0% {
			transform: translate(0, 0)
		}

		50% {
			transform: translate(-25px, 25px)
		}

		100% {
			transform: translate(0, 0)
		}
	`,
	bottomRight: keyframes`
		0% {
			transform: translate(0, 0)
		}

		50% {
			transform: translate(25px, 25px)
		}

		100% {
			transform: translate(0, 0)
		}
	`,
	right: keyframes`
		0% {
			transform: translate(0, 0)
		}

		50% {
			transform: translate(25px, 0)
		}

		100% {
			transform: translate(0, 0)
		}
	`,
};

const ImageCard = styled.div`
	background: #fff;
	box-shadow: 0 0 30px rgba(0,0,0,.4);
	transition: z-index .2s step-end;
	height: 180px;
	position: absolute;
	width: 180px;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	will-change: transform;

	${ultraTinyMedia} {
		width: 200px;
		height: 200px;
	}

	${tinyMedia} {
		width: 220px;
		height: 220px;
	}

	${smallMedia} {
		height: 260px;
		width: 260px;
	}

	${largeMedia} {
		height: 280px;
		width: 280px;
	}

	${giantMedia} {
		height: 300px;
		width: 300px;
	}

	&:nth-child(1) {
		left: 0;
		top: 100px;
		z-index: 3;
	}

	&:nth-child(2) {
		left: 100px;
		top: 250px;
		z-index: 2;

		${ultraTinyMedia} {
			left: 110px;
		}

		${smallMedia} {
			left: 200px;
		}

		${largeMedia} {
			left: 220px;
			top: 270px;
		}

		${giantMedia} {
			left: 240px;
			top: 290px;
		}
	}

	&:nth-child(3) {
		left: 0;
		top: 400px;
		z-index: 1;

		${largeMedia} {
			top: 440px;
		}

		${giantMedia} {
			top: 480px;
		}
	}

	${props => props.active && `
	z-index: 4 !important;
	`}

	${props => props.animation && imageCardAnimations[props.animation] && css`
	animation: ${imageCardAnimations[props.animation]} both .5s cubic-bezier(.175, .885, .32, 1.275);
	`}
`;

const ImageCardImage = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	z-index: 1;
	background-image: url(${props => props.image});
`;

const InfoSection = styled.div`
	background-image: url(/images/portrait-diamonds.svg);
	background-size: cover;
	padding: 150px 5% 50px;
	position: relative;
	margin: 0 auto;
	clip-path: ${clipPathTop};

	a {
		font-weight: bold;
	}

	${H2} {
		text-align: center;
	}

	${IntroText} {
		text-align: center;
	}

	> ${H3} {
		text-align: center;
	}

	${smallMedia} {
		background-image: url(/images/diamonds-bg.png);
	}
`;

const PlaytestSection = styled.div`
	padding: 150px 5% 64px;
	position: relative;
	margin: 0 auto;
	text-align: center;
`;

const PlayTestCarousel = styled.div`
	display: flex;
	flex-wrap: no-wrap;
	left: 0;
	right: 0;
	position: absolute;
	height: 100vh;
	overflow-x: scroll;
	justify-content: flex-start;

	+ * {
		margin-top: calc(28vw + 64px);
	}

	${mediumMedia} {
		overflow: hidden;
		justify-content: space-around;

		+ * {
			margin-top: calc(14vw + 64px);
		}
	}

	&:after {
		content: '';
		display: block;
		width: 40px;
		height: 100%;
		flex-shrink: 0;
	}
`;

const PlayTestVideoContainer = styled.div`
	min-width: 0;
	flex-shrink: 0;
	overflow: hidden;
	box-shadow: ${props => props.theme.boxShadowLarge};
	position: relative;
	border-radius: 8px;

	width: 56vw;
	height: 28vw;
	margin: 0 8px;

	${mediumMedia} {
		width: 24vw;
		height: 12vw;
		margin: 0 12px;
	}

	&:first-child {
		margin-left: 40px;
	}

	video {
		width: 100%;
		position: absolute;
		left: 50%; /* % of surrounding element */
		top: 50%;
		transform: translate(-50%, -50%); /* % of current element */
	}
`;

const StatsSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	position: relative;
	top: -200px;
	margin-bottom: -200px;
	background-image: url(/images/portrait-blocks.svg);
	background-size: cover;
	clip-path: ${clipPathBottom};
	padding: 350px 0 100px 0;

	${SectionContainer} {
		display: flex;
		flex-direction: column;

		${mediumMedia} {
			flex-direction: row;
		}
	}

	${smallMedia} {
		background-image: url(/images/violetbg.png);
	}
`;

const StatsContent = styled.div`
	margin: 5% 0;
	text-align: center;

	${mediumMedia} {
		max-width: 45%;
		text-align: left;
		margin-left: 5%;
	}

	${largeMedia} {
		margin-top: 2%;
	}
`;

const StatsInnerContainer = styled.div`
	margin: 0 auto 10%;
	max-width: 424px;

	${mediumMedia} {
		width: 424px;
		max-width: 45%;
		margin-right: 5%;
	}
`;

const StatTop = styled.div`
	display: flex;

	img {
		margin: 8px 0;
		height: 40px;
	}
`;

const StatTopText = styled.div`
	margin-left: 16px;
`;

const StatTitle = styled.div`
	${Title}
	font-size: 24px;
	line-height: 28px;

	${smallMedia} {
		font-size: 28px;
		line-height: 32px;
	}
`;

const StatSubtitle = styled.div`
	${Title}
	font-weight: semi-bold;
	font-size: 16px;
	line-height: 24px;

	${smallMedia} {
		font-size: 18px;
		line-height: 24px;
	}
`;

const StatDescription = styled.div`
	display: none;
	margin-top: 16px;
	color: #8B9AB1;
	font-size: 14px;
	line-height: 20px;

	${smallMedia} {
		font-size: 16px;
		line-height: 24px;
	}
`;

const Stat = styled.div`
	position: relative;
	background: #fff;
	padding: 32px;
	box-shadow: ${lightColors.boxShadowLarge};

	${props => (props.distFromActive === 0 ? `
		z-index: 2;
		height: 184px;

		${StatDescription} {
			display: block;
		}
	` : '')}

	${props => (props.distFromActive === 1 ? `
		margin: 0 8px;
		z-index: 1;

		${smallMedia} {
			margin: 0 16px;
		}
	` : '')}

	${props => (props.distFromActive === 2 ? `
		margin: 0 16px;
		z-index: 0;

		${smallMedia} {
			margin: 0 32px;
		}
	` : '')}
`;

const FactCards = styled.div`
	margin-top: 40px;
	margin-bottom: 50px;

	${smallMedia} {
		padding: 50px 0;
		margin: 0;
	}

	${mediumMedia} {
		display: flex;
		justify-content: space-evenly;
		padding: 50px 0;
	}

	${ButtonWrapper} {
		margin-top: 25px;
		align-self: center;
	}

	${H3} {
		font-size: 24px;
		line-height: 28px;
		margin-bottom: 16px;

		${smallMedia} {
			font-size: 28px;
			line-height: 32px;
		}
	}
`;

const FactCard = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-bottom: 50px;
	width: 100%;

	> svg {
		height: 48px;
		width: 48px;
		margin: 0 auto;

		${smallMedia} {
			height: 80px;
			width: 80px;
		}
	}

	${mediumMedia} {
		padding-left: 25px;
		padding-right: 25px;
	}

	${largeMedia} {
		width: 33.33%;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const Testimonials = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	margin-top: 40px;
	margin-bottom: 50px;

	${smallMedia} {
		flex-direction: row;
		padding: 50px 0;
		margin: 0;
		padding: 50px 0;
	}

	${ButtonWrapper} {
		margin-top: 25px;
		align-self: center;
	}

	${H3} {
		font-size: 24px;
		line-height: 28px;
		margin-bottom: 16px;

		${smallMedia} {
			font-size: 28px;
			line-height: 32px;
		}
	}
`;

const TestimonialImage = styled.div`
	position: relative;
	width: max-content;
	margin: 0 auto;

	img {
		border-radius: 100%;
		margin: 0 auto;
		width: 168px;
		max-width: 100%;
		vertical-align: bottom;

		${smallMedia} {
			width: 200px;
		}
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		border-radius: 100%;
		box-shadow: ${lightColors.boxShadowMedium};
		opacity: 0;
		transition: opacity 0.2s ease-out;
	}
`;

const Testimonial = styled.a`
	display: flex;
	flex-direction: column;
	text-align: center;
	margin: 0 auto 50px;
	width: 50%;

	${mediumMedia} {
		padding-left: 25px;
		padding-right: 25px;
		width: 33.33%;
	}

	${smallMedia} {
		width: 25%;
	}

	&:last-child {
		margin-bottom: 0;
	}

	${P}, strong {
		font-size: 18px;
		line-height: 28px;
	}

	${P} {
		color: ${lightColors.grey3};
		margin: 16px 0 8px;
	}

	&:hover ${TestimonialImage} {
		&:after {
			opacity: 1;
		}
	}
`;

const PartnersSection = styled.section`
	background: #F0F5FC;
	padding: 80px 0;

	${smallMedia} {
		padding: 120px 0;
	}

	${mediumMedia} {
		padding: 160px 0;
	}

	${largeMedia} {
		padding: 200px 0;
	}

	${H2} {
		margin-bottom: 32px;
		text-align: center;
	}

	${Testimonials} + ${P} {
		text-align: center;
		font-weight: bold;
		font-size: 24px;
		line-height: 30px;
	}
`;

const PartnerGrid = styled.div`
	max-width: 944px;
	margin: 32px auto 0;

	&:after {
		content: "";
		display: block;
		clear: both;
	}
`;

const Partner = styled.div`
	position: relative;
	height: 100px;
	margin-bottom: 8px;
	padding-left: 0;
	padding-right: 0;
	float: left;
	width: 50%;

	${tinyMedia} {
		height: 200px;
	}

	${mediumMedia} {
		width: 25%;
	}
`;

const PartnerImage = styled.div`
	position: relative;
	height: 100%;
	width: auto;
	background-color: #fff;
	background-image: url(${props => props.image});
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	box-shadow: ${lightColors.boxShadowSmall};
	margin: 4px;
	filter: grayscale(100%);
	transition: filter 0.2s ease-out;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #fff;
		opacity: .2;
		transition: opacity 0.2s ease-out;
	}

	&:hover {
		filter: none;

		&:after {
			opacity: 0;
		}
	}
`;

const playtestVideos = [
	'/videos/playtest-level-devil.mp4',
	'/videos/playtest-blumgi-ball.mp4',
	'/videos/playtest-drive-mad.mp4',
	'/videos/playtest-venge.mp4',
	'/videos/playtest-monkey-mart.mp4',
];

const randomizedPlaytestVideos = [];
while (playtestVideos.length > 0) {
	const randomIndex = Math.floor(Math.random() * playtestVideos.length);
	randomizedPlaytestVideos.push(playtestVideos[randomIndex]);
	playtestVideos.splice(randomIndex, 1);
}

const SUBMIT_LINK = '/share'; // link to a form to submit a game

const ImageCards = () => {
	const [activeImageCard, setActiveImageCard] = useState(null);

	return (
		<ImageCardsContainer>
			<ImageCard
				animation={activeImageCard === 1 || activeImageCard === 2 ? 'topLeft' : null}
				active={activeImageCard === 1}
				onMouseEnter={evt => {
					spawnEmoji(evt.target);
					setActiveImageCard(1);
				}}
			>
				<ImageCardImage image={getCDNImage({ src: '/images/game-card_venge.png', width: 600 })} />
			</ImageCard>
			<ImageCard
				animation={activeImageCard === 2 ? 'right' : activeImageCard === 1 ? 'bottomRight' : activeImageCard === 3 ? 'topRight' : null}
				active={activeImageCard === 2}
				onMouseEnter={evt => {
					spawnEmoji(evt.target);
					setActiveImageCard(2);
				}}
			>
				<ImageCardImage image={getCDNImage({ src: '/images/game-card_subway-surfers.png', width: 600 })} />
			</ImageCard>
			<ImageCard
				animation={activeImageCard === 2 || activeImageCard === 3 ? 'bottomLeft' : null}
				active={activeImageCard === 3}
				onMouseEnter={evt => {
					spawnEmoji(evt.target);
					setActiveImageCard(3);
				}}
			>
				<ImageCardImage image={getCDNImage({ src: '/images/game-card_stickman-hook.png', width: 600 })} />
			</ImageCard>
		</ImageCardsContainer>
	);
};

const handleSpawnEmoji = evt => {
	spawnEmoji(evt.target);
};

const getDistFromActiveStat = (activeStat, index) => Math.abs(activeStat - index);

const StatsModule = () => {
	const [activeStat, setActiveStat] = useState(0);

	return (
		<StatsSection>
			<SectionContainer>
				<StatsInnerContainer>
					<Stat
						distFromActive={getDistFromActiveStat(activeStat, 0)}
						onMouseOver={() => setActiveStat(0)}
						onMouseEnter={evt => {
							spawnEmoji(evt.target);
						}}
					>
						<StatTop>
							<GlobeIcon />
							<StatTopText>
								<StatTitle>#1 brand</StatTitle>
								<StatSubtitle>in 100+ countries</StatSubtitle>
							</StatTopText>
						</StatTop>
						<StatDescription>
							Poki is the leading web gaming platform, powered by a community of 300+ game developers.
						</StatDescription>
					</Stat>
					<Stat
						distFromActive={getDistFromActiveStat(activeStat, 1)}
						onMouseOver={() => setActiveStat(1)}
						onMouseEnter={evt => {
							spawnEmoji(evt.target);
						}}
					>
						<StatTop>
							<GameControllerIcon />
							<StatTopText>
								<StatTitle>700,000,000</StatTitle>
								<StatSubtitle>gameplays per month</StatSubtitle>
							</StatTopText>
						</StatTop>
						<StatDescription>
							Countless hours of fun across skill games, sports games, two-player games, you-name-it-games…
						</StatDescription>
					</Stat>
					<Stat
						distFromActive={getDistFromActiveStat(activeStat, 2)}
						onMouseOver={() => setActiveStat(2)}
						onMouseEnter={evt => {
							spawnEmoji(evt.target);
						}}
					>
						<StatTop>
							<WowIcon />
							<StatTopText>
								<StatTitle>34 languages</StatTitle>
								<StatSubtitle>localized for fun</StatSubtitle>
							</StatTopText>
						</StatTop>
						<StatDescription>
							From Arabic to Zhōngwén. A tailored experience for the whole wide world.
						</StatDescription>
					</Stat>
				</StatsInnerContainer>
				<StatsContent>
					<H2>Reach the world, together</H2>
					<IntroText>
						<P>
							Your creation deserves the spotlight. With Poki, you gain access to 60 million players, strategic know-how, and tailored personal support to lift your game to global fame.
						</P>
					</IntroText>
					<ButtonWrapper marketingButton onMouseEnter={handleSpawnEmoji} to={SUBMIT_LINK}>Boost your game</ButtonWrapper>
				</StatsContent>
			</SectionContainer>
		</StatsSection>
	);
};

const LandingPage = () => {
	const user = useSelectUser();
	const permissions = useSelectPermissions();

	if (useSelectIsAuthenticated()) {
		if (!user) return null; // User is being authenticated in the background
		return (
			<Navigate to={{ pathname: `/${checkPermissions(permissions, [['can_read_all_games']]) ? 'admin' : user.team.code}` }} />
		);
	}

	return (
		<Container>
			<Helmet key="LandingPage">
				<title>Poki for Developers - Turn your game into a hit</title>
			</Helmet>
			<MarketingHeader />
			<Page>
				<HeroSection>
					<SectionContainer>
						<HeroColumn>
							<HeroContent>
								<H1>Turn your game into a hit</H1>
								<IntroText>
									<P>
										Leverage the power of the web and our user-friendly dev tools to launch, improve, and skyrocket your game to success.
									</P>
								</IntroText>
								<ButtonWrapper marketingButton onMouseEnter={handleSpawnEmoji} to={SUBMIT_LINK}>Get started</ButtonWrapper>
							</HeroContent>
						</HeroColumn>
						<HeroColumn>
							<ImageCards />
						</HeroColumn>
					</SectionContainer>
				</HeroSection>
				<StatsModule />
				<PlaytestSection>
					<SectionContainer>
						<H2>Instant feedback with zero effort</H2>
						<IntroText>
							With Poki Playtesting, our latest feature, you&apos;ll get insights from real players in no time. See your game through the eyes of users and leverage our analytics to refine and improve your game. All for free, nada, zilch!
						</IntroText>
						<ButtonWrapper marketingButton onMouseEnter={handleSpawnEmoji} to={SUBMIT_LINK}>Start a playtest</ButtonWrapper>

					</SectionContainer>
				</PlaytestSection>
				<PlayTestCarousel>
					{randomizedPlaytestVideos.map(src => (
						<PlayTestVideoContainer>
							<video type="video/mp4" src={src} autoPlay loop muted playsInline />
						</PlayTestVideoContainer>
					))}
				</PlayTestCarousel>
				<InfoSection>
					<SectionContainer>
						<H2>Fair pay, fair play</H2>
						<IntroText>
							Monetize seamlessly. We secure top-tier CPMs for you through the best brand deals. You make the game, we handle the rest.
						</IntroText>
						<FactCards>
							<FactCard>
								<BallRugbyIcon />
								<H3>Better ads</H3>
								<P>
									We strive for the best user experience with seamless ads, increasing playtime and profit.
								</P>
							</FactCard>
							<FactCard>
								<DevDocIcon />
								<H3>Lightweight SDK</H3>
								<P>
									Hassle-free monetization️ in just a few clicks. Simple setup, serious rewards.
								</P>
							</FactCard>
							<FactCard>
								<MonetizeIcon />
								<H3>Speedy payments</H3>
								<P>
									We offer high revenue shares and payouts within 5 working days to fuel your passion.
								</P>
							</FactCard>
						</FactCards>
					</SectionContainer>
				</InfoSection>
				<PartnersSection>
					<SectionContainer>
						<H2>We&apos;re in this together</H2>
						<Testimonials>
							<Testimonial href="https://medium.com/poki/blumgi-my-journey-on-the-web-how-i-reached-100m-players-in-2-years-as-an-indie-game-developer-6071481a3461" target="_blank" rel="noopener noreferrer" onMouseEnter={handleSpawnEmoji}>
								<TestimonialImage>
									<img src={getCDNImage({ src: '/images/blumgi.png', width: 400, height: 400 })} alt="Blumgi" />
								</TestimonialImage>
								<P>
									&ldquo;I reached 100M players on the web in 2 years as an indie game developer&rdquo;
								</P>
								<strong>Blumgi</strong>
							</Testimonial>
							<Testimonial href="https://medium.com/poki/higher-success-rates-with-playtests-1e1316dd70fb" target="_blank" rel="noopener noreferrer" onMouseEnter={handleSpawnEmoji}>
								<TestimonialImage>
									<img src={getCDNImage({ src: '/images/onrush.png', width: 400, height: 400 })} alt="OnRush" />
								</TestimonialImage>
								<P>
									&ldquo;Poki&apos;s playtest allows us to better understand player behavior, which increases our success rate!&rdquo;
								</P>
								<strong>OnRush Studio</strong>
							</Testimonial>
						</Testimonials>
						<P>
							Trusted by the biggest brands in the industry
						</P>
						<PartnerGrid>
							{[
								'/images/defold.png',
								'/images/madbox-partner.png',
								'/images/unico-studios.png',
								'/images/onrush-studio.png',
								'/images/sybo.png',
								'/images/imangi-studios.png',
								'/images/away-fl-logo.png',
								'/images/nitrome.png',
								'/images/unity.png',
								'/images/fancade.png',
								'/images/new-star-games.png',
								'/images/7-spot-games.png',
							].map(img => (
								<Partner key={`partner-${img}`} onMouseEnter={handleSpawnEmoji}>
									<PartnerImage image={
										getCDNImage({ src: img, width: 500 })
									}
									/>
								</Partner>
							))}
						</PartnerGrid>
					</SectionContainer>
				</PartnersSection>

				<MarketingFooter />
			</Page>
		</Container>
	);
};

export default LandingPage;
