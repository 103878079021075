import React, { useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import { lightColors, smallMedia, mediumMedia, tinyMedia } from 'shared/vars';
import BestPracticesIcon from 'shared/designTokens/icons/ui/small/BestPracticesIcon';
import CodeIcon from 'shared/designTokens/icons/ui/small/CodeIcon';
import LoginIcon from 'shared/designTokens/icons/ui/small/LoginIcon';
import MenuIcon from 'shared/designTokens/icons/ui/medium/MenuIcon';
import CloseIcon from 'shared/designTokens/icons/ui/medium/CloseIcon';
import getAppRoot from 'shared/utils/getAppRoot';
import Button from 'app/src/components/ui/Button';

const hamburgerBreakpoint = smallMedia;

const ButtonWrapper = styled(Button)``;

const PokiLogo = styled.img`
	height: 32px;

	${mediumMedia} {
		height: 45px;
	}
`;

const HeaderWrapper = styled.header`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 16px 20px;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: space-between;

	${tinyMedia} {
		padding: 40px;
	}
`;

const NavigationAnchor = styled.a`
	display: flex;
	align-items: center;
	color: ${lightColors.denimBlue};
	font-weight: bold;
	font-size: 16px;
	line-height: 1em;
	gap: 8px;
	flex-shrink: 0;

	${mediumMedia} {
		font-size: 18px;
	}

	&:hover {
		color: ${lightColors.pokiBlue}
	}

	svg [fill] {
		fill: ${lightColors.denimBlue};
	}
	&:hover {
		svg [fill] {
			fill: ${lightColors.pokiBlue};
		}
	}

	${props => props.$active && `
		color: ${lightColors.pokiBlue};

		svg [fill] {
			fill: ${lightColors.pokiBlue};
		}
	`}
`;

const NavigationBarItem = styled.div`
	margin: 0 10px;
	align-items: center;

	${props => (props.$invisibleOnHamburgerBreakpoint ? `
		display: none;

		${hamburgerBreakpoint} {
			display: flex;
		}
	` : `
		display: flex;
	`)}
`;

const NavigationBar = styled.div`
	height: 36px;
	display: flex;
	flex-shrink: 0;

	${NavigationAnchor} {
		svg {
			display: none;
		}

		${smallMedia} {
			svg {
				display: block;
			}
		}
	}
`;

const HamburgerHeaderWrapper = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	padding: 16px 20px;

	${tinyMedia} {
		padding: 40px;
	}

	${NavigationBarItem} {
		opacity: 0;
		pointer-events: none;
	}

	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const HamburgerNavigation = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 60px;

	${tinyMedia} {
		margin-top: 108px;
	}

	${NavigationBarItem} {
		margin: 0 0 24px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	${NavigationAnchor} {
		font-size: 18px;
		margin: 0;
	}
`;

const HamburgerCTA = styled.div`
	margin-top: 40px;
	font-size: 18px;
	line-height: 28px;
`;

const HamburgerCTAButtons = styled.div`
	margin-top: 16px;
	display: flex;

	${ButtonWrapper} {
		margin-right: 8px;
		box-shadow: none;
	}
`;

const HamburgerMenuIcon = styled(MenuIcon)`
	cursor: pointer;
	min-width: 0;
	flex-shrink: 0;

	${hamburgerBreakpoint} {
		display: none;
	}

	[fill] {
		fill: ${lightColors.denimBlue};
	}

	&:hover {
		[fill] {
			fill: ${lightColors.pokiBlue};
		}
	}
`;

const HamburgerCloseIcon = styled(CloseIcon)`
	cursor: pointer;
	align-self: end;

	[fill] {
		fill: ${lightColors.denimBlue};
	}

	&:hover {
		[fill] {
			fill: ${lightColors.pokiBlue};
		}
	}
`;

const HamburgerMenu = styled.div`
	display: none;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	z-index: 2;
	padding: 40px;
	flex-direction: column;

	${props => props.$open && `
	display: flex;

	${hamburgerBreakpoint} {
		display: none;
	}
	`}
`;

const HamburgerFooterNavigation = styled.div`
	margin-top: 64px;
`;

const HamburgerFooterNavigationAnchor = styled.a`
	display: block;
	color: ${lightColors.grey3};
	font-size: 16px;
	line-height: 24px;
	margin-top: 12px;

	&:first-child {
		margin-top: 0;
	}

	&:hover {
		color: ${lightColors.pokiBlue};
	}
`;

const menuItems = [
	{
		title: 'Developer Guide',
		to: '/guide',
		icon: <BestPracticesIcon />,
		invisibleOnHamburgerBreakpoint: true,
	},
	{
		title: 'Poki SDK',
		to: 'https://sdk.poki.com',
		target: '_blank',
		icon: <CodeIcon />,
		invisibleOnHamburgerBreakpoint: true,
	},
	{
		title: 'Sign In',
		to: `${getAppRoot()}/signin/`,
		icon: <LoginIcon />,
		hideInHamburger: true,
	},
];

const hamburgerFooterMenuItems = [
	{
		title: 'About Poki',
		to: 'https://about.poki.com',
		target: '_blank',
	},
	{
		title: 'Our blog',
		to: 'https://blog.poki.com',
		target: '_blank',
	},
	{
		title: 'Let\'s play',
		to: 'https://poki.com',
		target: '_blank',
	},
	{
		title: 'Privacy Policy',
		to: 'https://poki.com/en/c/privacy-statement',
		target: '_blank',
	},
	{
		title: 'Terms of Use',
		to: 'https://poki.com/en/c/terms-of-use',
		target: '_blank',
	},
];

const MarketingHeader = () => {
	const location = useLocation();

	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<>
			<HeaderWrapper>
				<NavigationAnchor href="/">
					<PokiLogo src="/images/logo.svg" alt="Poki for Developers" />
				</NavigationAnchor>
				<NavigationBar>
					{menuItems.map(item => (
						<NavigationBarItem key={item.to} $invisibleOnHamburgerBreakpoint={item.invisibleOnHamburgerBreakpoint}>
							<NavigationAnchor href={item.to} target={item.target} $active={location.pathname === item.to}>
								{item.icon}
								{item.title}
							</NavigationAnchor>
						</NavigationBarItem>
					))}
					<HamburgerMenuIcon onClick={() => setIsMenuOpen(true)} />
				</NavigationBar>
			</HeaderWrapper>
			<HamburgerMenu $open={isMenuOpen}>
				<HamburgerHeaderWrapper>
					<NavigationAnchor href="/">
						<PokiLogo src="/images/logo.svg" alt="Poki for Developers" />
					</NavigationAnchor>
					<NavigationBar>
						{menuItems.map(item => ( // These will not be shown but we still need to do this to ensure cross icon is in the same place as hamburger icon...
							<NavigationBarItem key={item.to} $invisibleOnHamburgerBreakpoint={item.invisibleOnHamburgerBreakpoint}>
								<NavigationAnchor href={item.to} target={item.target} $active={location.pathname === item.to}>
									{item.icon}
									{item.title}
								</NavigationAnchor>
							</NavigationBarItem>
						))}
						<HamburgerCloseIcon onClick={() => setIsMenuOpen(false)} />
					</NavigationBar>
				</HamburgerHeaderWrapper>
				<HamburgerNavigation>
					{menuItems.map(item => (!item.hideInHamburger ? (
						<NavigationBarItem key={item.to}>
							<NavigationAnchor href={item.to} target={item.target} $active={location.pathname === item.to}>
								{item.icon}
								{item.title}
							</NavigationAnchor>
						</NavigationBarItem>
					) : null))}
				</HamburgerNavigation>
				<HamburgerCTA>
					Join the movement. Launch, improve and skyrocket your game to success.
					<HamburgerCTAButtons>
						<ButtonWrapper marketingButton noShadow to="/share">Join us</ButtonWrapper>
						<ButtonWrapper marketingButton noShadow secondary to={`${getAppRoot()}/signin/`}>Sign in</ButtonWrapper>
					</HamburgerCTAButtons>
				</HamburgerCTA>
				<HamburgerFooterNavigation>
					{hamburgerFooterMenuItems.map(item => (
						<HamburgerFooterNavigationAnchor key={item.to} href={item.to} target={item.target}>
							{item.title}
						</HamburgerFooterNavigationAnchor>
					))}
				</HamburgerFooterNavigation>
			</HamburgerMenu>
		</>
	);
};

export default MarketingHeader;
