import React from 'react';
import styled from 'styled-components';

import { Container, Paragraph, H3, H2 } from 'site/src/components/styled';
import { IconContainer } from 'site/src/components/guide.styled';
import HomeIcon from 'shared/designTokens/icons/ui/small/HomeIcon';
import CodeIcon from 'shared/designTokens/icons/ui/small/CodeIcon';
import UsersIcon from 'shared/designTokens/icons/ui/small/UsersIcon';
import MobileCheckIcon from 'shared/components/svg/MobileCheck';
import Stars from 'site/src/components/Stars';
import { mediumMedia, smallMedia } from 'shared/vars';
import getCDNImage from 'site/src/utils/getCDNImage';

const HeaderContainer = styled(Container)`
	display: flex;
	gap: 16px;

	${mediumMedia} {
		gap: 24px;
	}
`;

const Header = styled(Container)`
	display: flex;
	flex-direction: column;
`;

const EngineTitle = styled(H2)`
	font-size: 28px;
	line-height: 1.15;
	margin-top: 0 !important;
`;

const EngineSubtitle = styled(Paragraph)`
	margin-bottom: 8px;
`;

const Par = styled(Paragraph)`
	margin-bottom: 1em;

	&:last-child {
		margin-bottom: 0;
	}
`;

const GameEngineLogo = styled.img`
	border-radius: 8px;
	box-shadow: ${props => props.theme.boxShadowMedium};
	width: 80px;
	height: 80px;
	${mediumMedia} {
		width: 152px;
		height: 152px;
	}
`;

const PartnerBox = styled(Container)`
	background-image: url('/images/guide/heart_pattern.svg');
	padding: 24px;
	border-radius: 16px;
	background-size: cover;
	display: inline-block;
	font-weight: 700;
	line-height: 20px;
	font-size: 16px;
	width: 100%;

	${smallMedia} {
		width: auto;
	}
`;

const DiveContainer = styled(Container)`
	background-color: ${props => props.theme.grey7};
	margin: 1em -20px;
	border-radius: 8px;
	padding: 20px;

	${mediumMedia} {
		padding: 48px;
		margin-left: 0;
		margin-right: 0;
		border-radius: 24px;
		padding: 48px;
	}
`;

const Characteristic = styled.h4`
	display: flex;
	justify-content: space-between;
	margin: 16px 0 4px 0;
	font-size: 16px;
	line-height: 1.5;
`;

const Link = styled.a`
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 16px;
	line-height: 1.5;
	font-weight: 700;
`;

const HeaderSuperContainer = styled(Container)`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-bottom: 1em;
`;

const DiveTitle = styled(H3)`
	display: flex;
	gap: 12px;
	margin-top: 40px !important;
	margin-bottom: 18px;
	align-items: center;
	font-family: "Proxima Nova", sans-serif;
	font-size: 22px;
	line-height: 163.636%;

	&:first-child {
		margin-top: 0 !important;
	}
`;

const EngineIconContainer = styled(IconContainer)`
	padding: 8px;

	& > svg {
		width: 24px;
		height: 24px;
	}
`;

const GameLink = styled.a`
	font-weight: 700;
`;

const GameExamples = styled.ul`
	font-size: 16px;
	line-height: 1.5;
	padding-left: 24px;
`;

const GameEngine = ({ data, id }) => (
	<>
		<HeaderSuperContainer>
			<HeaderContainer>
				<GameEngineLogo
					src={
						getCDNImage({ src: `/images/guide/engine_logos/Engine-${id}.png`, width: 800 })
					}
					alt={`${data.engine} logo`}
				/>
				<Header>
					<EngineTitle id={id}>{data.engine}</EngineTitle>
					<EngineSubtitle>{data['sub-title']}</EngineSubtitle>
					<Link href={data.website} target="_blank"><HomeIcon />{data.website.replace(/^https?:\/\//, '').replace(/\/$/, '')}</Link>
					<Link href={data.documentation} target="_blank"><CodeIcon />Documentation</Link>
					{Object.values(data['community-links']).map(({ label, url }, i) => (
						// eslint-disable-next-line react/no-array-index-key
						<Link href={url} key={i} target="_blank"><UsersIcon />{label}</Link>
					))}

				</Header>
			</HeaderContainer>
			{data['official-partner'] && <PartnerBox>Official partner of Poki</PartnerBox>}
		</HeaderSuperContainer>
		<Par large>{data.description}</Par>
		<Par large>
			Have a look at some of the games to see what {data.engine} is capable of:
		</Par>
		<GameExamples>
			{data.games.map((game, i) => (
				// eslint-disable-next-line react/no-array-index-key
				<li key={i}><GameLink href={game.url}>{game.title}</GameLink> by {game.developer}</li>
			))}
		</GameExamples>
		<DiveContainer>
			<DiveTitle>
				<EngineIconContainer backgroundColor="#FBE756">
					<MobileCheckIcon />
				</EngineIconContainer>
				(Mobile) web tech fit
			</DiveTitle>
			<Characteristic>File size of empty project<Stars rating={data.sections['tech-fit']['file-size-of-empty-project'].stars} /></Characteristic>
			<Par>{data.sections['tech-fit']['file-size-of-empty-project'].description}</Par>
			<Characteristic>Support for mobile web<Stars rating={data.sections['tech-fit']['support-for-mobile-web'].stars} /></Characteristic>
			<Par>
				{data.sections['tech-fit']['support-for-mobile-web'].description}
			</Par>
			<DiveTitle>
				<EngineIconContainer backgroundColor="#EAC0FC">
					<MobileCheckIcon />
				</EngineIconContainer>
				What you need
			</DiveTitle>
			<Characteristic>2D capabilities<Stars rating={data.sections.capabilities['2D'].stars} /></Characteristic>
			<Par>
				{data.sections.capabilities['2D'].description}
			</Par>
			<Characteristic>3D capabilities<Stars rating={data.sections.capabilities['3D'].stars} /></Characteristic>
			<Par>
				{data.sections.capabilities['3D'].description}
			</Par>
			<Characteristic>Multiplayer<Stars rating={data.sections.capabilities.multiplayer.stars} /></Characteristic>
			<Par>
				{data.sections.capabilities.multiplayer.description}
			</Par>
			<DiveTitle>
				<EngineIconContainer backgroundColor="#83FFE7">
					<UsersIcon />
				</EngineIconContainer>
				Developer (team) fit
			</DiveTitle>
			<Characteristic>Working together<Stars rating={data.sections['team-fit'].collaboration.stars} /></Characteristic>
			<Par>
				{data.sections['team-fit'].collaboration.description}
			</Par>
			<Characteristic>Licensing<Stars rating={data.sections['team-fit'].licensing.stars} /></Characteristic>
			<Par>
				{data.sections['team-fit'].licensing.description}
			</Par>
		</DiveContainer>
	</>
);

export default GameEngine;
