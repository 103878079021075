import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { Container, BackgroundImage, Page } from 'site/src/components/styled';
import MarketingHeader from 'site/src/components/MarketingHeader';
import ArrowLeftIcon from 'shared/designTokens/icons/ui/small/ArrowLeftIcon';
import { ParentDirectory, Main, Navigation, Title, SubTitle, ContentParagraph } from 'site/src/components/guide.styled';
import SignUpCTA from 'site/src/components/SignUpCTA';
import MarketingFooter from 'shared/components/MarketingFooter';
import ChevronRightIcon from 'shared/designTokens/icons/ui/small/ChevronRightIcon';
import { mediumMedia } from 'shared/vars';
import getCDNImage from 'site/src/utils/getCDNImage';

const Link = styled.a`
	font-weight: 700;
	display: flex;
`;

const Flex = styled.div`
	display: flex;
	flex-wrap: wrap;

	${mediumMedia} {
		flex-wrap: nowrap;
	}

	img {
		width: 160px;
		height: auto;
		object-fit: contain;
	}
`;

const GuideGameDevToolsPage = () => (
	<Container>
		<Helmet key="GuideContentPage">
			<title>Poki for Developers - Web game guide</title>
		</Helmet>
		<BackgroundImage center backgroundUrl="/images/header_heart.svg" />
		<MarketingHeader />
		<Page>
			<ParentDirectory href="/guide"><ArrowLeftIcon />Overview</ParentDirectory>
			<Main>
				<Title>Poki game development tools</Title>
				<ContentParagraph extraLarge>
					We want to help you make the best web games possible so we’ve been working on a few tools aimed making your life as a developer easier on web! As we work on more tools they will all be added here so keep an eye out!
				</ContentParagraph>

				<SubTitle>Poki Inspector</SubTitle>
				<ContentParagraph large>
					Testing your game can be hard because you may not know where to look for improvements. At Poki, we QA all games which means we know what the biggest factors are when it comes to a games’ success. Using our Inspector tool, you can check off some of the biggest requirements for your game yourself, as well as test for mobile and see how optimized your tech is. Just upload a web build and off you go!
				</ContentParagraph>

				<ContentParagraph large>
					<Link href="https://inspector.poki.dev/" target="_blank"><ChevronRightIcon /> Use the Inspector</Link>
				</ContentParagraph>

				<SubTitle>Poki Networking Library</SubTitle>
				<Flex>
					<ContentParagraph large>
						The Poki Networking Library (Netlib) is a peer-to-peer library for web games that uses WebRTC datachannels to provide direct UDP connections between players. You can think of it like the Steam Networking Library, but for the web. Netlib aims to make using WebRTC in game development as simple as the WebSocket interface.<br />
						Your game doesn’t have to be on Poki to make use of the Poki Networking Library.
					</ContentParagraph>
					<img src={getCDNImage({ src: '/images/guide/netlib.png', width: 200 })} alt="Poki Networking Library icon" />
				</Flex>

				<ContentParagraph large>
					<Link href="https://github.com/poki/netlib" target="_blank"><ChevronRightIcon /> Read more</Link>
				</ContentParagraph>

				<SubTitle>Arbitrary User Data Store (AUDS)</SubTitle>
				<ContentParagraph large>
					Ever run into the case where your game needs a simple backend to store levels or other user-generated content? Well, we have created AUDS, The Poki Arbitrary User Data Store just for that. It is a prototype that lets you store any data in our backend, and it will return a code for you to share with a player. You could use this server for user-generated content like levels, a leaderboard, or even a non-real-time multiplayer game!
				</ContentParagraph>
				<ContentParagraph large>
					For now, your game needs to be on Poki to make use of AUDS.
				</ContentParagraph>

				<ContentParagraph large>
					<Link href="https://documenter.getpostman.com/view/24775109/2s8YzMZmNY" target="_blank"><ChevronRightIcon /> Read more</Link>
				</ContentParagraph>
			</Main>
			<Navigation	/>
			<SignUpCTA />
			<MarketingFooter />
		</Page>
	</Container>
);

export default GuideGameDevToolsPage;
