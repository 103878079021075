import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { smallMedia, mediumMedia, largeMedia } from 'shared/vars';

import { BackgroundImage, Container, Page } from 'site/src/components/styled';
import MarketingFooter from 'shared/components/MarketingFooter';
import MarketingHeader from 'site/src/components/MarketingHeader';
import SignUpCTA from 'site/src/components/SignUpCTA';
import BalloonIcon from 'shared/designTokens/icons/content/BalloonIcon';
import MonetizeIcon from 'shared/designTokens/icons/content/MonetizeIcon';
import WebGameEngineIcon from 'shared/designTokens/icons/content/WebGameEngineIcon';
import PlayerDeviceReportIcon from 'shared/designTokens/icons/content/PlayerDeviceReportIcon';
import EasyAccessIcon from 'shared/designTokens/icons/content/EasyAccessIcon';
import LocalizationIcon from 'shared/designTokens/icons/content/LocalizationIcon';
import ThumbnailIcon from 'shared/designTokens/icons/content/ThumbnailIcon';
import PokiNetlibIcon from 'shared/components/svg/PokiNetlibIcon';

const DeveloperGuideTitle = styled.h1`
	font: 700 36px/1.25 Torus, Montserrat, Arial, sans-serif;
	margin: 0 0 16px;
	padding: 0 32px;

	${smallMedia} {
		text-align: center;
		font-size: 40px;
		margin-bottom: 24px;
	}
	${largeMedia} {
		font-size: 52px;
	}
`;

const DeveloperGuideIntroText = styled.article`
	line-height: 1.5;
	padding: 0 32px;
	max-width: 922px;
	margin: 0 auto 40px;

	p {
		margin: 0 0 16px;
	}

	${smallMedia} {
		text-align: center;
	}
	${largeMedia} {
		font-size: 22px;
	}
`;

const DeveloperGuideList = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	padding: 0 32px;
	margin: 0 auto 70px;
	max-width: 922px;

	h2 {
		margin-top: 30px;
		font-size: 18px;
		line-height: 32px;
		margin-bottom: 0;

		${smallMedia} {
			font-size: 22px;
			line-height: 36px;
		}
	}
`;

const DeveloperGuideCard = styled.a`
	background: ${props => props.theme.grey7};
	display: flex;
	width: 100%;
	color: ${props => props.theme.denimBlue};
	border-radius: 16px;
	padding: 20px 16px;

	${smallMedia} {
		padding: 36px 32px;
	}
`;

const DeveloperGuideCardCaption = styled.div`
	margin-left: 16px;

	${smallMedia} {
		margin-left: 32px;
	}
`;

const DeveloperGuideIcon = styled.div`
	width: 48px;
	height: 48px;
`;

const DeveloperGuideCardTitle = styled.div`
	font: 700 20px/1.2 Torus, Montserrat, Arial, sans-serif;
	margin: 0 0 4px;

	${smallMedia} {
		font-size: 24px;
		margin-bottom: 8px;
	}
	${mediumMedia} {
		font-size: 28px;
	}
`;

const DeveloperGuideCardText = styled.div`
	font-size: 16px;
	line-height: 24px;
`;

const GUIDE_CATEGORIES = [
	{
		title: 'The foundations for games on web',
		cards: [
			{
				icon: WebGameEngineIcon,
				title: 'Web game engines',
				text: 'There are tons of options when it comes to web tech. Let us help you choose which one fits you best!',
				href: '/guide/web-game-engines',
			},
			{
				icon: PlayerDeviceReportIcon,
				title: 'Player Device Report',
				text: 'Is your audience ready for your tech? Here are some insights into the hardware and devices used by millions of players on Poki.',
				href: '/guide/player-device-report',
			},
		],
	},
	{
		title: 'Tips and tricks for getting the most out of your web game',
		cards: [
			{
				icon: EasyAccessIcon,
				title: 'Easy access',
				text: 'Getting players in-game fast is vital for success on web. Here’s some ideas to help your game’s onboarding flow and tech',
				href: '/guide/easy-access',
			},
			{
				icon: BalloonIcon,
				title: 'Engagement',
				text: 'There’s no one size fits all solution to increasing your engagement, so here’s a few ideas you could try to keep players hooked!',
				href: '/guide/engagement',
			},
			{
				icon: LocalizationIcon,
				title: 'Localization',
				text: 'Let the world play! Supporting multiple languages increases the accessibility and engagement of your game.',
				href: '/guide/localization',
			},
			{
				icon: MonetizeIcon,
				title: 'Monetization',
				text: 'Think about monetization early in development to make ads part of the game. Adding it in later might hurt the UX.',
				href: '/guide/monetization',
			},
			{
				icon: ThumbnailIcon,
				title: 'Game thumbnail',
				text: 'The thumbnail is the first thing players see of your game so make sure it makes an impact.',
				href: '/guide/thumbnail',
			},
		],
	},
	{
		title: 'Poki Development Tools',
		cards: [
			{
				icon: PokiNetlibIcon,
				title: 'Poki game development tools',
				text: 'Poki has been working on a few tools to help you in your web development journey. Take a look and see how they can make your life easier.',
				href: '/guide/game-dev-tools',
			},
		],
	},
];

const DeveloperGuide = () => (
	<Container>
		<Helmet key="DeveloperGuide">
			<title>Poki for Developers - Developer Guide</title>
		</Helmet>
		<BackgroundImage center backgroundUrl="/images/header_diamond.svg" />
		<MarketingHeader />

		<Page>
			<DeveloperGuideTitle>Developer Guide</DeveloperGuideTitle>
			<DeveloperGuideIntroText>
				<p>Games for web are not that different from games on other platforms, though there are web specific best practices to take into consideration. Follow these tips to increase your chances of success on web!</p>
			</DeveloperGuideIntroText>

			<DeveloperGuideList>
				{GUIDE_CATEGORIES.map(category => (
					<React.Fragment key={category.title}>
						<h2>{category.title}</h2>
						{category.cards.map(card => (
							<DeveloperGuideCard key={card.href} href={card.href}>
								<DeveloperGuideIcon>
									<card.icon />
								</DeveloperGuideIcon>
								<DeveloperGuideCardCaption>
									<DeveloperGuideCardTitle>{card.title}</DeveloperGuideCardTitle>
									<DeveloperGuideCardText>{card.text}</DeveloperGuideCardText>
								</DeveloperGuideCardCaption>
							</DeveloperGuideCard>
						))}
					</React.Fragment>
				))}
			</DeveloperGuideList>
		</Page>

		<SignUpCTA />
		<MarketingFooter />
	</Container>
);

export default DeveloperGuide;
