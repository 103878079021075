import React from 'react';
import styled from 'styled-components';

import { H1, P, SectionContainer } from 'site/src/components/styled';
import { smallMedia } from 'shared/vars';

const StyledSectionContainer = styled(SectionContainer)`
	max-width: 900px;
	padding-top: 0;
	padding-bottom: 200px;

	${smallMedia} {
		padding-top: 100px;
		text-align: center;
	}
`;

const StyledH1 = styled(H1)`
	margin-bottom: 24px;
`;

const ThankYouMessage = () => (
	<StyledSectionContainer>
		<StyledH1>Thank you 🤟</StyledH1>
		<P>
			We received your request!
			<br /><br />
			If we think your game would be a good fit for early access to Poki for Developers, you’ll hear back from us in a few days. If you don’t hear anything, stay tuned! We’ll soon be opening up Poki for Developers for all web game developers.
		</P>
	</StyledSectionContainer>
);

export default ThankYouMessage;
