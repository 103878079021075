import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { EMPTY, fromEvent, of, switchMap } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import styled, { css } from 'styled-components';
import { orderBy } from 'lodash';
import moment from 'moment';

import DocumentIcon from 'shared/designTokens/icons/ui/small/DocumentIcon';
import SearchIcon from 'shared/designTokens/icons/ui/small/SearchIcon';
import ArrowLeftIcon from 'shared/designTokens/icons/ui/small/ArrowLeftIcon';

import { ParentDirectory, Navigation } from 'site/src/components/guide.styled';
import SignUpCTA from 'site/src/components/SignUpCTA';
import useEpic from 'app/src/hooks/useEpic';
import { openModal } from 'app/src/actions/client';
import ThemeProvider from 'app/src/utils/ThemeProvider';
import { useSelectModal } from 'app/src/selectors/client';
import { Container, BackgroundImage, Page, H1, SectionContainer, H3, Paragraph } from 'site/src/components/styled';
import MarketingHeader from 'site/src/components/MarketingHeader';
import MarketingFooter from 'shared/components/MarketingFooter';
import TextInput from 'app/src/components/input/TextInput';
import IconButton from 'app/src/components/ui/IconButton';
import Tooltip from 'shared/components/Tooltip';
import { lightColors, largeMedia, mediumMedia } from 'shared/vars';
import formatNumber from 'shared/utils/formatNumber';
import _ from 'shared/copy';

const COLOURS = {
	operatingSystems: [lightColors.dataBlue5, lightColors.dataBlue4, lightColors.dataBlue3, lightColors.dataBlue2, lightColors.dataBlue1, lightColors.dataGrey7],
	browsers: [lightColors.dataYellow5, lightColors.dataYellow4, lightColors.dataYellow3, lightColors.dataYellow2, lightColors.dataYellow1, lightColors.dataGrey7],
	deviceType: [lightColors.dataPurple5, lightColors.dataPurple4, lightColors.dataPurple3],
	cpuCores: [lightColors.dataOrange5, lightColors.dataOrange4, lightColors.dataOrange3, lightColors.dataOrange2, lightColors.dataOrange1, lightColors.dataGrey7],
	screenAspectRatio: [lightColors.dataGreen5, lightColors.dataGreen4, lightColors.dataGreen3, lightColors.dataGreen2, lightColors.dataGreen1, lightColors.dataGrey7],
	devicePixelRatios: [lightColors.dataRed5, lightColors.dataRed4, lightColors.dataRed3, lightColors.dataRed2, lightColors.dataRed1, lightColors.dataGrey7],
	webglVersions: [lightColors.dataGrey5, lightColors.dataGrey1],
	webgpu: [lightColors.dataGrey5, lightColors.dataGrey1],
};

const WEGL_EXTENSIONS_COUNT = 10;

const getColorOpacity = value => {
	switch (true) {
		case value >= 90:
			return {
				color: lightColors.dataGreen5,
				hover: lightColors.dataGreen1,
			};
		case (value < 90 && value >= 50):
			return {
				color: lightColors.dataOrange5,
				hover: lightColors.dataOrange1,
			};
		default:
			return {
				color: lightColors.dataRed5,
				hover: lightColors.dataRed1,
			};
	}
};

const joinSmallPercentages = (data, limit) => {
	let sum;

	if (data) {
		sum = data.slice(limit).reduce((acc, a) => acc + (a.user_percentage || 0), 0);
	}

	return {
		ratio: 'Other',
		user_percentage: sum,
	};
};

const ColumnsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	${mediumMedia} {
		flex-direction: row;
	}
`;

const ColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 32px;
	${mediumMedia} {
		width: calc(50% - 32px);
	}
`;

const DoubleColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	${largeMedia} {
		flex-direction: row;
	}
`;

const Card = styled.div`
	position: relative;
	width: 100%;
	padding: 32px;
	border-radius: var(--borderRadius, 8px);
	${props => props.halfSize && css`
		${largeMedia} {
			width: calc(50% - 16px);
		}
	`}
	${props => props.center && `
		text-align: center;
	`}
	${props => props.search && css`
		min-height: 470px;
		max-height: 500px;
		overflow: auto;
	`}
	&& {
		background: #ffffff;
		box-shadow: ${lightColors.boxShadowSmall};
	}
`;

const CardHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 0 24px;
`;

const CardTitle = styled(H3)`
	margin: 0;
	font-size: 24px;
	font-family: 'Proxima Nova', sans-serif;
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	${Card} {
		height: 100%;
		padding: 24px;
	}
`;

const StyledH1 = styled(H1)`
	font-size: 36px;
	margin: 0 0 24px;
`;

const HorizontalGraph = styled.div`
	display: flex;
	width: 100%;
	height: 32px;
	box-shadow: ${lightColors.boxShadowSmall};
	border-radius: 8px;
	margin: 0 0 20px;
`;

const HorizontalGraphItem = styled.div`
	flex: 1 1 ${props => `${props.percentage * 100}%`};
	background: ${props => props.colorCode};
	&:first-child {
		border-radius: 8px 0 0 8px;
	}
	&:last-child {
		border-radius: 0 8px 8px 0;
	}
`;

const GraphLegend = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	p {
		font-size: 12px;
	}
`;

const GraphLegendItem = styled.div`
	display: flex;
	align-items: center;
`;

const GraphCircle = styled.div`
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: ${props => props.colorCode};
	margin: 0 8px;
`;

const PercentageCardWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
	margin: 8px 0 0;
`;

const PercentageCardText = styled.span`
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: ${lightColors.dataGrey4};
`;

const PercentageCard = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 12px;
	background: ${lightColors.dataGrey8};
	border-radius: 4px;
	min-width: 105px;
	height: 40px;
	&:hover {
		background-color: ${props => getColorOpacity(props.value).hover}
	}
`;

const ColoredPercentage = styled(PercentageCardText)`
	font-size: 20px;
	margin: 0 5px 0 0;
	color: ${props => getColorOpacity(props.value).color}
`;

const StyledTextInput = styled(TextInput)`
	max-width: 100%;
	margin: 0;
`;

const StyledTooltip = styled(Tooltip)`
	width: max-content;
`;

const TooltipTitle = styled.div`
	font-size: 14px;
	font-weight: 700;
`;

const TooltipItem = styled.div`
	display: grid;
	grid-template-columns: 15px 1fr 0.5fr;
	grid-gap: 12px;
	margin: 6px 0;
	align-items: center;
`;

const Divider = styled.hr`
	border-top: 1px solid ${lightColors.dataGrey7};
`;

const TotalPercentage = styled.div`
	display: flex;
	justify-content: space-between;

	span {
		color: ${lightColors.dataGrey4};
		font-size: 14px;
		font-weight: 700;
	}
`;

const NoResultsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 70px;
	p {
		align-self: flex-start;
	}
`;

const CatSVG = styled.img`
	width: 256px;
	height: 136px;
`;

const GraphTooltip = ({ data, children, title, colorCode }) => (
	<StyledTooltip
		placement="top"
		followCursor="horizontal"
		arrow
		content={(
			<>
				<TooltipTitle>{title}</TooltipTitle>
				{data?.map((d, index) => (
					<TooltipItem key={(d.name || d.ratio || d.cores || d.version)}>
						<GraphCircle colorCode={colorCode[index]} />
						<Paragraph small capitalize><strong>{(d.name || d.ratio || d.cores || `V.${d.version}`)}</strong></Paragraph>
						<Paragraph small capitalize align="right"><strong>{formatNumber(d.user_percentage * 100)}%</strong></Paragraph>
					</TooltipItem>
				))}
				<Divider />
				<TotalPercentage>
					<span>Total</span>
					<span>100%</span>
				</TotalPercentage>
			</>
		)}
	>
		{children}
	</StyledTooltip>
);

const PlayerDeviceReportPage = () => {
	const dispatch = useDispatch();

	const modal = useSelectModal();

	const [data, setData] = useState();
	const [webglExtensions, setWebglExtensions] = useState();
	const [query, setQuery] = useState('');

	const getGraphLegend = (graphData, colorCode) => {
		if (graphData && colorCode) {
			return (
				graphData.map((item, index) => (
					<GraphLegendItem key={(item.name || item.ratio || item.cores || item.version)}>
						<GraphCircle colorCode={colorCode[index]} />
						<Paragraph small capitalize color={lightColors.dataGrey4}>
							<strong>{(item.name || item.ratio || item.cores || `V.${item.version}`)}</strong>
						</Paragraph>
					</GraphLegendItem>
				))
			);
		}

		return [];
	};

	// ESC to clear
	useEpic(() => (
		fromEvent(window, 'keydown').pipe(
			switchMap(e => {
				const { keyCode } = e;
				if (keyCode === 27) { // ESC
					setQuery('');
					e.preventDefault();
					return EMPTY;
				}

				return EMPTY;
			}),
		)
	), [query]);

	const handleViewRaw = (title, items, columns) => (
		dispatch(openModal({ key: 'view-raw-data', data: { title, items, columns } }))
	);

	useEffect(() => {
		if (modal) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [modal]);

	useEffect(() => {
		fromFetch('https://a.poki-cdn.com/hardware-report/latest.json').pipe(
			switchMap(response => {
				if (response.ok) {
					return response.json();
				} else {
					return of({ error: true, message: `Error ${response.status}` });
				}
			}),
		).subscribe({
			next: result => {
				setData({
					...result,
					raw_aspect_ratios: result.aspect_ratios,
					raw_device_pixel_ratios: result.device_pixel_ratios,
					aspect_ratios: [
						...result.aspect_ratios.slice(0, 5),
						joinSmallPercentages(result?.aspect_ratios, 5),
					],
					device_pixel_ratios: [
						...result.device_pixel_ratios.slice(0, 5),
						joinSmallPercentages(result?.device_pixel_ratios, 5),
					],
					webgpu: [
						{
							name: 'Yes',
							user_percentage: result.webgpu.user_percentage,
						},
						{
							name: 'No',
							user_percentage: 1 - result.webgpu.user_percentage,
						},
					],
				});
				setWebglExtensions(result?.webgl?.webgl_extensions);
			},
		});
	}, []);

	useEffect(() => {
		if (data) {
			setWebglExtensions(data.webgl.webgl_extensions.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1));
		} else {
			setWebglExtensions(data?.webgl.webgl_extensions);
		}
	}, [query]);

	return (
		<ThemeProvider forceLightMode>
			<Container>
				<Helmet key="PlayerDeviceReportPage">
					<title>Poki for Developers - Reach the world on web</title>
				</Helmet>
				<BackgroundImage center backgroundUrl="/images/header_coin.svg" />
				<MarketingHeader />
				<Page>
					<ParentDirectory href="/guide"><ArrowLeftIcon />Overview</ParentDirectory>
					<SectionContainer>
						<ColumnsWrapper>
							<ColumnContainer>
								<TitleContainer>
									<Card style={{ '--borderRadius': '8px 8px 0 0' }}>
										<StyledH1>Poki Player Device Report</StyledH1>
										<Paragraph large>
											{_`hardwareReportSubtitle`}
										</Paragraph>
									</Card>
									<Card center style={{ '--borderRadius': '0 0 8px 8px' }}>
										<Paragraph large dangerouslySetInnerHTML={{ __html: _`hardwareReportDisclaimer${{ lastUpdate: moment.utc(data?.last_update).format('ll') }}` }} />
									</Card>
								</TitleContainer>
								<Card>
									<CardHeader>
										<CardTitle>Operating System</CardTitle>
										<IconButton
											title="View Raw Data"
											icon={DocumentIcon}
											onClick={() => handleViewRaw('Operating System', data.operating_systems, [
												{
													title: 'Extension name',
													content: ({ item }) => item.name,
												},
												{
													title: 'Percentage (%)',
													content: ({ item }) => formatNumber(item.user_percentage * 100),
												},
											])}
										/>
									</CardHeader>
									<GraphTooltip data={data?.operating_systems} title="Operating System" colorCode={COLOURS.operatingSystems}>
										<HorizontalGraph>
											{data?.operating_systems?.map((os, index) => (
												<HorizontalGraphItem percentage={os.user_percentage} colorCode={COLOURS.operatingSystems[index]} />
											))}
										</HorizontalGraph>
									</GraphTooltip>
									<GraphLegend>
										{getGraphLegend(data?.operating_systems, COLOURS.operatingSystems)}
									</GraphLegend>
								</Card>
								<Card>
									<CardHeader>
										<CardTitle>Browsers</CardTitle>
										<IconButton
											title="View Raw Data"
											icon={DocumentIcon}
											onClick={() => handleViewRaw('Browsers', data.browsers, [
												{
													title: 'Browser',
													content: ({ item }) => item.name,
												},
												{
													title: 'Percentage (%)',
													content: ({ item }) => formatNumber(item.user_percentage * 100),
												},
											])}
										/>
									</CardHeader>
									<GraphTooltip data={data?.browsers} title="Browsers" colorCode={COLOURS.browsers}>
										<HorizontalGraph>
											{data?.browsers?.map((b, index) => (
												<HorizontalGraphItem percentage={b.user_percentage} colorCode={COLOURS.browsers[index]} />
											))}
										</HorizontalGraph>
									</GraphTooltip>
									<GraphLegend>
										{getGraphLegend(data?.browsers, COLOURS.browsers)}
									</GraphLegend>
								</Card>
								<DoubleColumn>
									<Card>
										<CardHeader>
											<CardTitle>CPU Cores</CardTitle>
											<IconButton
												title="View Raw Data"
												icon={DocumentIcon}
												onClick={() => handleViewRaw('CPU Cores', data.cpus, [
													{
														title: 'Cores',
														content: ({ item }) => item.cores,
													},
													{
														title: 'Percentage (%)',
														content: ({ item }) => formatNumber(item.user_percentage * 100),
													},
												])}
											/>
										</CardHeader>
										<GraphTooltip data={data?.cpus} title="CPU Cores" colorCode={COLOURS.cpuCores}>
											<HorizontalGraph>
												{data?.cpus?.map((c, index) => (
													<HorizontalGraphItem percentage={c.user_percentage} colorCode={COLOURS.cpuCores[index]} />
												))}
											</HorizontalGraph>
										</GraphTooltip>
										<GraphLegend>
											{getGraphLegend(data?.cpus, COLOURS.cpuCores)}
										</GraphLegend>
									</Card>
								</DoubleColumn>
								<Card>
									<CardHeader>
										<CardTitle>Full Screen Aspect Ratios</CardTitle>
										<IconButton
											title="View Raw Data"
											icon={DocumentIcon}
											onClick={() => handleViewRaw('Full Screen Aspect Ratios', data.raw_aspect_ratios, [
												{
													title: 'Aspect Ratio',
													content: ({ item }) => item.ratio,
												},
												{
													title: 'Percentage (%)',
													content: ({ item }) => formatNumber(item.user_percentage * 100),
												},
												{
													title: 'Example Resolution',
													content: ({ item }) => item.example_resolution,
												},
											])}
										/>
									</CardHeader>
									<GraphTooltip data={data?.aspect_ratios} title="Full Screen Aspect Ratios" colorCode={COLOURS.screenAspectRatio}>
										<HorizontalGraph>
											{data?.aspect_ratios.map((a, index) => (
												<HorizontalGraphItem percentage={a.user_percentage} colorCode={COLOURS.screenAspectRatio[index]} />
											))}
										</HorizontalGraph>
									</GraphTooltip>
									<GraphLegend>
										{getGraphLegend(data?.aspect_ratios, COLOURS.screenAspectRatio)}
									</GraphLegend>
								</Card>
								<Card>
									<CardHeader>
										<CardTitle>Device Pixel Ratios</CardTitle>
										<IconButton
											title="View Raw Data"
											icon={DocumentIcon}
											onClick={() => handleViewRaw('Device Pixel Ratios', data.raw_device_pixel_ratios, [
												{
													title: 'Pixel Ratio',
													content: ({ item }) => item.ratio,
												},
												{
													title: 'Percentage (%)',
													content: ({ item }) => formatNumber(item.user_percentage * 100),
												},
											])}
										/>
									</CardHeader>
									<GraphTooltip data={data?.device_pixel_ratios} title="Device Pixel Ratios" colorCode={COLOURS.devicePixelRatios}>
										<HorizontalGraph>
											{data?.device_pixel_ratios.map((a, index) => (
												<HorizontalGraphItem key={a.ratio} percentage={a.user_percentage} colorCode={COLOURS.devicePixelRatios[index]} />
											))}
										</HorizontalGraph>
									</GraphTooltip>
									<GraphLegend>
										{getGraphLegend(data?.device_pixel_ratios.slice(0, 6), COLOURS.devicePixelRatios)}
									</GraphLegend>
								</Card>
							</ColumnContainer>
							<ColumnContainer>
								<Card>
									<CardHeader>
										<CardTitle>Audio Formats</CardTitle>
										<IconButton
											title="View Raw Data"
											icon={DocumentIcon}
											onClick={() => handleViewRaw('Audio Formats', data.audio_formats, [
												{
													title: 'Device',
													content: ({ item }) => item.name,
												},
												{
													title: 'Percentage (%)',
													content: ({ item }) => formatNumber(item.user_percentage * 100),
												},
												{
													title: 'Can Play',
													content: ({ item }) => item.can_play,
												},
											])}
										/>
									</CardHeader>
									<PercentageCardWrapper>
										{orderBy(data?.audio_formats, ['user_percentage'], ['desc'])?.map(a => (
											a.can_play === 'probably' ? (
												<PercentageCard value={a.user_percentage * 100}>
													<ColoredPercentage value={a.user_percentage * 100}>
														{formatNumber(a.user_percentage * 100)}%
													</ColoredPercentage>
													<PercentageCardText>{a.name}</PercentageCardText>
												</PercentageCard>
											) : null
										))}
									</PercentageCardWrapper>
								</Card>
								<Card>
									<CardHeader>
										<CardTitle>WebGPU</CardTitle>
									</CardHeader>
									<GraphTooltip data={data?.webgpu} title="WebGPU" colorCode={COLOURS.webgpu}>
										<HorizontalGraph>
											{data?.webgpu?.map((w, index) => (
												<HorizontalGraphItem percentage={w.user_percentage} colorCode={COLOURS.webgpu[index]} />
											))}
										</HorizontalGraph>
									</GraphTooltip>
									<GraphLegend>
										{getGraphLegend(data?.webgpu, COLOURS.webgpu)}
									</GraphLegend>
								</Card>
								<Card>
									<CardHeader>
										<CardTitle>WebGL Version</CardTitle>
									</CardHeader>
									<GraphTooltip data={data?.webgl?.webgl_versions} title="WebGL Version" colorCode={COLOURS.webglVersions}>
										<HorizontalGraph>
											{data?.webgl?.webgl_versions?.map((w, index) => (
												<HorizontalGraphItem percentage={w.user_percentage} colorCode={COLOURS.webglVersions[index]} />
											))}
										</HorizontalGraph>
									</GraphTooltip>
									<GraphLegend>
										{getGraphLegend(data?.webgl?.webgl_versions, COLOURS.webglVersions)}
									</GraphLegend>
								</Card>
								<Card search>
									<CardHeader>
										<CardTitle>WebGL Extensions</CardTitle>
										<IconButton
											title="View Raw Data"
											icon={DocumentIcon}
											onClick={() => handleViewRaw('WebGL Extensions', data.webgl.webgl_extensions, [
												{
													title: 'Extension',
													content: ({ item }) => item.name,
												},
												{
													title: 'Percentage (%)',
													content: ({ item }) => formatNumber(item.user_percentage * 100),
												},
											])}
										/>
									</CardHeader>
									<StyledTextInput
										icon={SearchIcon}
										valueSetter={setQuery}
										value={query}
										placeholder="Search all extensions"
										description={query ? _`pressEscToClear` : null}
										shortDesc
									/>
									{!query ? (
										<Paragraph dangerouslySetInnerHTML={{ __html: _`topWebglExtensions${{ extensionsDisplayed: WEGL_EXTENSIONS_COUNT, totalExtensions: data?.webgl?.webgl_extensions?.length }}` }} />
									) : webglExtensions?.length > 0 && (
										<Paragraph dangerouslySetInnerHTML={{ __html: _`showingWebglExtensionsResult${{ extensionsDisplayed: webglExtensions?.length, totalExtensions: data?.webgl?.webgl_extensions?.length }}` }} />
									)}
									{webglExtensions?.length > 0 ? (
										<PercentageCardWrapper>
											{orderBy(webglExtensions?.slice(0, (!query ? WEGL_EXTENSIONS_COUNT : webglExtensions?.length)), ['user_percentage'], ['desc'])?.map(a => (
												<PercentageCard value={a.user_percentage * 100}>
													<ColoredPercentage value={a.user_percentage * 100}>
														{formatNumber(a.user_percentage * 100)}%
													</ColoredPercentage>
													<PercentageCardText>{a.name}</PercentageCardText>
												</PercentageCard>
											))}
										</PercentageCardWrapper>
									) : (
										<NoResultsWrapper>
											<Paragraph large dangerouslySetInnerHTML={{ __html: _`noMatchesFound` }} />
											<CatSVG src="/svg/cat.svg" alt="cat" />
										</NoResultsWrapper>
									)}
								</Card>
								<Card>
									<CardHeader>
										<CardTitle>Device Capabilities</CardTitle>
										<IconButton
											title="View Raw Data"
											icon={DocumentIcon}
											onClick={() => handleViewRaw('Device Capabilities', data.device_capabilities, [
												{
													title: 'Device',
													content: ({ item }) => item.name,
												},
												{
													title: 'Percentage (%)',
													content: ({ item }) => formatNumber(item.user_percentage * 100),
												},
											])}
										/>
									</CardHeader>
									<PercentageCardWrapper>
										{orderBy(data?.device_capabilities, ['user_percentage'], ['desc'])?.map(d => (
											<PercentageCard value={d.user_percentage * 100}>
												<ColoredPercentage value={d.user_percentage * 100}>
													{formatNumber(d.user_percentage * 100)}%
												</ColoredPercentage>
												<PercentageCardText>{d.name}</PercentageCardText>
											</PercentageCard>
										))}
									</PercentageCardWrapper>
								</Card>
							</ColumnContainer>
						</ColumnsWrapper>
					</SectionContainer>
				</Page>
			</Container>
			<Navigation
				nextLink="/guide/easy-access"
				nextLabel="Easy access"
			/>
			<SignUpCTA />
			<MarketingFooter />
		</ThemeProvider>
	);
};

export default PlayerDeviceReportPage;
