import React from 'react';
import { useRoutes } from 'react-router-dom';

import LandingPage from 'site/src/components/pages/LandingPage';
import ShareGamePage from 'site/src/components/pages/ShareGamePage';
import PlayerDeviceReportPage from 'site/src/components/pages/PlayerDeviceReportPage';
import GuideEasyAccessPage from 'site/src/components/pages/GuideEasyAccessPage';
import GuideEngagementPage from 'site/src/components/pages/GuideEngagementPage';
import GuideLocalizationPage from 'site/src/components/pages/GuideLocalizationPage';
import GuideMonetizationPage from 'site/src/components/pages/GuideMonetizationPage';
import GuideThumbnailPage from 'site/src/components/pages/GuideThumbnailPage';
import GuideGameDevToolsPage from 'site/src/components/pages/GuideGameDevToolsPage';
import GuideWebEnginePage from 'site/src/components/pages/GuideGameEnginePage';
import DeveloperGuidePage from 'site/src/components/pages/DeveloperGuidePage';

import history from 'site/history';

import 'app/src/components/modals/ViewRawDataModal';

const routeConfig = [
	{
		path: '/',
		exact: true,
		element: LandingPage,
	},
	{
		path: '/share',
		exact: true,
		element: ShareGamePage,
	},
	{
		path: '/guide',
		exact: true,
		element: DeveloperGuidePage,
	},
	{
		path: '/guide/engagement',
		exact: true,
		element: GuideEngagementPage,
	},
	{
		path: '/guide/web-game-engines',
		exact: true,
		element: GuideWebEnginePage,
	},
	{
		path: '/guide/localization',
		exact: true,
		element: GuideLocalizationPage,
	},
	{
		path: '/guide/monetization',
		exact: true,
		element: GuideMonetizationPage,
	},
	{
		path: '/guide/thumbnail',
		exact: true,
		element: GuideThumbnailPage,
	},
	{
		path: '/guide/game-dev-tools',
		exact: true,
		element: GuideGameDevToolsPage,
	},
	{
		path: '/guide/player-device-report',
		exact: true,
		element: PlayerDeviceReportPage,
	},
	{
		path: '/guide/easy-access',
		exact: true,
		element: GuideEasyAccessPage,
	},
	{
		path: '*',
		element: () => { window.location.href = `https://app.poki.dev${history.location.pathname}`; },
		options: {
			noContainer: true,
		},
	},
];

const parseRoutes = (routes = []) => (
	routes.map(route => {
		const { children, ...routeProps } = route;

		return {
			...routeProps,
			element: <route.element />,
			children: parseRoutes(children),
		};
	})
);

const Routes = React.memo(() => useRoutes(parseRoutes(routeConfig)));

export default Routes;
